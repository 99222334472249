/* You can add global styles to this file, and also import other style files */

@import "~jsoneditor/dist/jsoneditor.min.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

mat-sidenav {
  overflow-x: hidden;
}

mat-nav-list {
  user-select: none;
  width: 230px;
}

mat-sidenav-content {
	overflow-x: hidden !important;
}

.mat-card-title.form {
  margin-left: 10px;
}

.mat-fab.mat-warn, .mat-flat-button.mat-warn, .mat-mini-fab.mat-warn, .mat-raised-button.mat-warn {
	background-color: #DF1A0C;
}

h4.form {
  margin: 20px 10px 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  color: #595959;
  font-weight: bold;
}

form div.row {
  display: grid;
  grid-template-columns: repeat(10,[col] 10%);
}

form div.buttons-row {
  display: grid;
  grid-template-columns: repeat(10,[col] 10%);
  margin-right: 20px;
}

form div.input-container {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

form div.button-container {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

form div.grid-col-1 {
	grid-column: auto/span 1;
}

form div.grid-col-2 {
  grid-column: auto/span 2;
}

form div.grid-col-3 {
  grid-column: auto/span 3;
}

form div.grid-col-4 {
	grid-column: auto/span 4;
}

form div.grid-col-5 {
  grid-column: auto/span 5;
}

form div.grid-col-8 {
	grid-column: auto/span 8;
}

form div.grid-col-10 {
  grid-column: auto/span 10;
}

form button.action {
  width: 168px;
  margin-left: 10px;
}

.mat-form-field {
  padding-right: 20px;
}

.mat-form-field-label {
  text-transform: uppercase;
}

mat-card.spacer {
  margin-bottom: 20px !important;
}

app-password {
	padding-right: 20px;
}

mat-dialog-content {
	overflow-x: hidden !important;
}

.table-filters {
	position: relative;
	width: 100%;
	display: flex;
}

.table-filters-title {
	color: #595959;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 14px;
	font-weight: 500;
	display: block;
	margin: 0 0 16px;
}

.table-filters-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	padding: 15px 0 0 0;
}

.table-header {
	position: relative;
	width: 100%;
	display: flex;
}

.table-header-title {
	color: #595959;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 14px;
	font-weight: 500;
	display: block;
	margin: 0 0 16px;
}

.table-header-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	padding: 15px 0 0 0;
}

.table-header-action {
	margin-top: -16px !important;
}

.edit-container {
	display: grid;
	grid-template-columns: 80% 20%;
}

.edit-actions-container {
	padding: 15px 30px;
}

button.edit-action {
	width: 168px;
	margin-left: 10px;
	margin-bottom: 15px;
}

file-upload label {
	height: 30px;
}

file-upload .button-text {
	vertical-align: super !important;
}
